define("discourse/plugins/discourse-whos-online/discourse/api-initializers/intitialize-whos-online-indicators", ["exports", "discourse-common/utils/decorators", "@ember/service", "discourse/lib/api"], function (_exports, _decorators, _service, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const PLUGIN_ID = "whos-online";

  var _default = (0, _api.apiInitializer)("0.8", api => {
    var _api$getCurrentUser, _dec, _obj, _dec2, _dec3, _obj2;

    const siteSettings = api.container.lookup("site-settings:main");
    const indicatorType = siteSettings.whos_online_avatar_indicator;

    if (indicatorType === "none") {
      return;
    }

    if (!(siteSettings.whos_online_display_public || ((_api$getCurrentUser = api.getCurrentUser()) === null || _api$getCurrentUser === void 0 ? void 0 : _api$getCurrentUser.trust_level) >= siteSettings.whos_online_display_min_trust_level)) {
      return;
    }

    document.documentElement.classList.add("whos-online-".concat(indicatorType));
    api.modifyClass("component:user-card-contents", (_dec = (0, _decorators.default)("user", "whosOnline.users.[]"), (_obj = {
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.inject)(),
      classNameBindings: ["isOnline:user-online"],

      isOnline(user) {
        return user && this.whosOnline.isUserOnline(user.id);
      }

    }, (_applyDecoratedDescriptor(_obj, "isOnline", [_dec], Object.getOwnPropertyDescriptor(_obj, "isOnline"), _obj)), _obj)));
    api.modifyClass("route:user", (_dec2 = (0, _decorators.default)("whosOnlineUserId", "whosOnline.users.[]"), _dec3 = (0, _decorators.observes)("isOnline"), (_obj2 = {
      pluginId: PLUGIN_ID,
      whosOnline: (0, _service.inject)(),

      async afterModel(model) {
        const superVal = await this._super(...arguments);
        this.set("whosOnlineUserId", model.id);
        this.updateBodyClass();
        return superVal;
      },

      isOnline(userId) {
        return userId && this.whosOnline.isUserOnline(userId);
      },

      updateBodyClass() {
        if (this.isOnline) {
          document.body.classList.add("user-page-online");
        } else {
          document.body.classList.remove("user-page-online");
        }
      },

      deactivate() {
        this._super();

        document.body.classList.remove("user-page-online");
      }

    }, (_applyDecoratedDescriptor(_obj2, "isOnline", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "isOnline"), _obj2), _applyDecoratedDescriptor(_obj2, "updateBodyClass", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "updateBodyClass"), _obj2)), _obj2)));

    if (siteSettings.whos_online_avatar_indicator_topic_lists) {
      var _dec4, _obj3, _dec5, _obj4;

      api.modifyClass("component:topic-list-item", (_dec4 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "whosOnline.users.[]"), (_obj3 = {
        pluginId: PLUGIN_ID,
        whosOnline: (0, _service.inject)(),
        classNameBindings: ["isOnline:last-poster-online"],

        isOnline(lastPosterId, lastPosterUserId) {
          return this.whosOnline.isUserOnline(lastPosterId || lastPosterUserId);
        }

      }, (_applyDecoratedDescriptor(_obj3, "isOnline", [_dec4], Object.getOwnPropertyDescriptor(_obj3, "isOnline"), _obj3)), _obj3)));
      api.modifyClass("component:latest-topic-list-item", (_dec5 = (0, _decorators.default)("topic.lastPoster.id", "topic.lastPosterUser.id", "whosOnline.users.[]"), (_obj4 = {
        pluginId: PLUGIN_ID,
        whosOnline: (0, _service.inject)(),
        classNameBindings: ["isOnline:last-poster-online"],

        isOnline(lastPosterId, lastPosterUserId) {
          return this.whosOnline.isUserOnline(lastPosterId || lastPosterUserId);
        }

      }, (_applyDecoratedDescriptor(_obj4, "isOnline", [_dec5], Object.getOwnPropertyDescriptor(_obj4, "isOnline"), _obj4)), _obj4)));
    }

    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,

      didInsertElement() {
        this._super();

        this.appEvents.on("whosonline:changed", this, this._whosOnlineCallback);
      },

      willDestroyElement() {
        this.appEvents.off("whosonline:changed", this, this._whosOnlineCallback);
      },

      _whosOnlineCallback(changedUserIds) {
        changedUserIds.forEach(id => {
          let postIds = this.attrs.posts.value.filter(_ref => {
            let {
              user_id
            } = _ref;
            return user_id === id;
          }).map(post => post.id);
          postIds.forEach(postId => {
            this.dirtyKeys.keyDirty("post-".concat(postId));
            this.dirtyKeys.keyDirty("post-".concat(postId, "-avatar-").concat(id), {
              onRefresh: "updateOnline"
            });
          });
        });
        this.queueRerender();
      }

    });
    api.reopenWidget("post-avatar", {
      buildKey: attrs => "post-".concat(attrs.id, "-avatar-").concat(attrs.user_id),

      isUserOnline(userId) {
        return this.register.lookup("service:whos-online").isUserOnline(userId);
      },

      defaultState(attrs) {
        return {
          online: this.isUserOnline(attrs.user_id)
        };
      },

      updateOnline() {
        this.state.online = this.isUserOnline(this.attrs.user_id);
      },

      buildClasses(attrs, state) {
        if (state.online) {
          return "user-online";
        }

        return [];
      }

    });
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-whos-online/discourse/components/whos-online-avatar", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class WhosOnlineAvatar extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", "a");

      _defineProperty(this, "attributeBindings", ["user.username:data-user-card", "user.path:href"]);
    }

  }

  _exports.default = WhosOnlineAvatar;
});
define("discourse/plugins/discourse-whos-online/discourse/components/whos-online", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WhosOnline = (_dec = (0, _computed.readOnly)("whosOnline.count"), _dec2 = (0, _object.computed)("whosOnline.users.[]"), _dec3 = (0, _object.computed)("users", "users.length"), _dec4 = (0, _object.computed)("count"), _dec5 = (0, _object.computed)("count"), _dec6 = (0, _object.computed)("count"), (_class = class WhosOnline extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "whosOnline", _descriptor, this);

      _initializerDefineProperty(this, "count", _descriptor2, this);
    }

    get users() {
      var _this$whosOnline$user;

      return (_this$whosOnline$user = this.whosOnline.users) === null || _this$whosOnline$user === void 0 ? void 0 : _this$whosOnline$user.slice(0, this.siteSettings.whos_online_maximum_display);
    }

    get hasUsers() {
      var _this$users;

      return ((_this$users = this.users) === null || _this$users === void 0 ? void 0 : _this$users.length) >= this.siteSettings.whos_online_minimum_display;
    }

    get hasCount() {
      return this.count >= this.siteSettings.whos_online_minimum_display;
    }

    get isLong() {
      return this.count >= this.siteSettings.whos_online_collapse_threshold;
    }

    get shouldDisplay() {
      if (this.count < this.siteSettings.whos_online_minimum_display && this.siteSettings.whos_online_hide_below_minimum_display) {
        return false;
      }

      return this.whosOnline.enabled;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "whosOnline", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "users", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "users"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasUsers", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasUsers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasCount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "hasCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isLong", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isLong"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldDisplay", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "shouldDisplay"), _class.prototype)), _class));
  _exports.default = WhosOnline;
});
define("discourse/plugins/discourse-whos-online/discourse/services/whos-online", ["exports", "@ember/service", "discourse/models/site", "@ember/object", "@ember/object/computed"], function (_exports, _service, _site, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WhosOnlineService = (_dec = (0, _computed.readOnly)("channel.users"), _dec2 = (0, _computed.readOnly)("channel.count"), _dec3 = (0, _computed.readOnly)("channel.countOnly"), (_class = class WhosOnlineService extends _service.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "presence", _descriptor, this);

      _initializerDefineProperty(this, "appEvents", _descriptor2, this);

      _initializerDefineProperty(this, "users", _descriptor3, this);

      _initializerDefineProperty(this, "count", _descriptor4, this);

      _initializerDefineProperty(this, "countOnly", _descriptor5, this);
    }

    init() {
      super.init(...arguments);
      this.set("channel", this.presence.getChannel("/whos-online/online"));

      if (this.enabled) {
        this.channel.subscribe(_site.default.currentProp("whos_online_state"));
      }

      this.addObserver("users.[]", this, this._usersChanged);
    }

    _usersChanged() {
      var _this$users;

      const currentUserIds = new Set(((_this$users = this.users) === null || _this$users === void 0 ? void 0 : _this$users.map(u => u.id)) || []);
      const prevUserIds = this._prevUserIds || new Set([]);
      const enteredUsers = [...currentUserIds].filter(x => !prevUserIds.has(x));
      const leftUsers = [...prevUserIds].filter(x => !currentUserIds.has(x));
      const changedUsers = [...enteredUsers, ...leftUsers];

      if (changedUsers.length > 0) {
        this.appEvents.trigger("whosonline:changed", changedUsers);
      }
    }

    get enabled() {
      var _this$currentUser;

      const anonAndLoginRequired = !this.currentUser && this.siteSettings.login_required;

      if (anonAndLoginRequired) {
        return false;
      }

      return this.siteSettings.whos_online_display_public || ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.trust_level) >= this.siteSettings.whos_online_display_min_trust_level;
    }

    isUserOnline(id) {
      var _this$channel, _this$channel$users;

      return !!((_this$channel = this.channel) !== null && _this$channel !== void 0 && (_this$channel$users = _this$channel.users) !== null && _this$channel$users !== void 0 && _this$channel$users.findBy("id", id));
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "presence", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "count", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "countOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "enabled", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "enabled"), _class.prototype)), _class));
  _exports.default = WhosOnlineService;
});
Ember.TEMPLATES["javascripts/components/whos-online-avatar"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],[[33,1]],[[\"avatarTemplatePath\",\"title\",\"imageSize\"],[\"avatar_template\",[33,1,[\"username\"]],\"small\"]]]],[1,\"\\n\"]],[],false,[\"avatar\",\"user\"]]","moduleName":"javascripts/discourse/templates/components/whos-online-avatar","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/whos-online"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[14,1,\"whos-online\"],[15,0,[52,[33,2],\"collapsed\"]],[12],[1,\"\\n    \"],[10,1],[15,\"title\",[28,[37,3],[\"whos_online.tooltip\"],[[\"count\"],[[33,4,[\"whos_online_active_timeago\"]]]]]],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.title\"],[[\"count\"],[[33,6]]]]],[1,\"\\n\"]],[]],[[[41,[33,7],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.count_only\"],[[\"count\"],[[33,6]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"whos_online.no_users\"],null]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[33,5],[[[42,[28,[37,9],[[28,[37,9],[[33,10]],null]],null],null,[[[1,\"        \"],[1,[28,[35,11],null,[[\"user\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"shouldDisplay\",\"isLong\",\"i18n\",\"siteSettings\",\"hasUsers\",\"count\",\"hasCount\",\"each\",\"-track-array\",\"users\",\"whos-online-avatar\"]]","moduleName":"javascripts/discourse/templates/components/whos-online","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/discovery-list-container-top/online_users_widget"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[34,0]],[1,\"\\n\"]],[],false,[\"whos-online\"]]","moduleName":"javascripts/discourse/templates/connectors/discovery-list-container-top/online_users_widget","isStrictMode":false});

